import { Space, Typography } from "antd";

interface CustomParagraphProps {
	maxRows?: number;
	children?: React.ReactNode;
	onToggle?: () => void;
	isExpanded?: boolean;
}

const { Paragraph, Text, Link } = Typography;

const CustomParagraph = ({
	children,
	maxRows = 3,
	onToggle = () => {},
	isExpanded,
}: CustomParagraphProps) => {
	if (!children) return <Text style={{ color: "slategray" }}>null</Text>;

	if (!isExpanded) {
		return (
			<Paragraph
				style={{ marginBottom: 0 }}
				ellipsis={{
					expandable: true,
					rows: maxRows,
					onExpand: onToggle,
					symbol: "More",
				}}
			>
				{children}
			</Paragraph>
		);
	}
	return (
		<Space style={{ width: "100%" }} align="start">
			<Paragraph style={{ marginBottom: 0 }}>
				{children}
				<Link onClick={onToggle}> Less</Link>
			</Paragraph>
		</Space>
	);
};

export default CustomParagraph;
