import { useContext, useMemo, useRef, useState } from "react";
import { CustomDashboardContext } from "../../contexts/context";
import {
	Button,
	Input,
	InputRef,
	Modal,
	Space,
	Table,
	TableColumnsType,
	TableColumnType,
	Tooltip,
	Typography,
} from "antd";
import useQuery from "../../hooks/useQuery";
import { FilterDropdownProps } from "antd/es/table/interface";
import { SafetyCertificateOutlined, SearchOutlined } from "@ant-design/icons";
import CustomParagraph from "../CustomParagraph";
import dayjs from "dayjs";
const { Link } = Typography;

const DrilldownModal = () => {
	const searchInput = useRef<InputRef>(null);

	// commented for now, would be usefull for highlighting searched text in table in the future
	// const [searchText, setSearchText] = useState("");
	// const [searchedColumn, setSearchedColumn] = useState("");
	const {
		state: customDashboardState,
		dispatch: customDashboardDispatch,
		measures,
		data_unauthorized,
	} = useContext(CustomDashboardContext);

	const measure = useMemo(() => {
		return measures?.find(
			(_measure: any) => _measure?.id === customDashboardState.currentDrilldown
		);
	}, [measures, customDashboardState.currentDrilldown]);

	const { data, loading } = useQuery({
		query: measure?.queryStatement,
		unauthorized_table: data_unauthorized,
		params:
			Object.entries(customDashboardState?.sliceValues).length !== 0
				? customDashboardState?.sliceValues
				: measure?.params,
	});

	const handleSearch = (
		selectedKeys: string[],
		confirm: FilterDropdownProps["confirm"],
		dataIndex: string
	) => {
		confirm();
		// setSearchText(selectedKeys[0]);
		// setSearchedColumn(dataIndex);
	};

	const handleReset = (
		clearFilters: () => void,
		confirm: FilterDropdownProps["confirm"]
	) => {
		clearFilters();
		confirm();
		// setSearchText("");
	};

	const getColumnSearchProps = (dataIndex: string): TableColumnType<any> => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
			close,
		}: any) => (
			<div
				className="filter-dropdown"
				style={{
					padding: 8,
					background: "#1f1f1f",
					border: "1px solid #303030",
					borderRadius: 8,
				}}
				onKeyDown={(e) => e.stopPropagation()}
			>
				<Input
					ref={searchInput}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					onPressEnter={() =>
						handleSearch(selectedKeys as string[], confirm, dataIndex)
					}
					style={{ marginBottom: 8, display: "block" }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() =>
							handleSearch(selectedKeys as string[], confirm, dataIndex)
						}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button
						onClick={() => clearFilters && handleReset(clearFilters, confirm)}
						size="small"
						style={{ width: 90 }}
					>
						Reset
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							close();
						}}
					>
						close
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered: boolean) => (
			<SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
		),
		onFilter: (value, record) => {
			const fieldValue = record[dataIndex] ?? "null";
			return fieldValue
				.toString()
				.toLowerCase()
				.includes((value as string).toLowerCase());
		},
		onFilterDropdownOpenChange: (visible) => {
			if (visible) {
				setTimeout(() => searchInput.current?.select(), 100);
			}
		},
	});

	const [expandedState, setExpandedState] = useState<{
		[key: string]: boolean;
	}>({});

	const toggleExpand = (key: string) => {
		setExpandedState((prev) => ({ ...prev, [key]: !prev[key] }));
	};

	const columns: TableColumnsType<any> | undefined = useMemo(() => {
		if (data?.length && data?.length > 0) {
			return Object.keys(data[0]).map((k: any, i) => ({
				dataIndex: k,
				key: k,
				title: k,
				width: 300,
				render: (value, record, index) => {
					return (
						<CustomParagraph
							key={`${k}-${i}`}
							maxRows={3}
							onToggle={() => toggleExpand(`${k}-${index}`)}
							isExpanded={!!expandedState[`${k}-${index}`]}
						>
							{value}
						</CustomParagraph>
					);
				},
				...getColumnSearchProps(k),
			}));
		} else {
			return undefined;
		}
	}, [data, expandedState]);

	return (
		<Modal
			destroyOnClose
			width={"80%"}
			cancelButtonProps={{ style: { display: "none" } }}
			title={
				<Space>
					<Link
						style={{ color: "#fff", fontSize: 16 }}
						onClick={() =>
							customDashboardDispatch({
								type: "EDIT_MEASURE",
								payload: measure?.id,
							})
						}
					>
						{measure?.name}
					</Link>
					{measure?.verified ? (
						<Tooltip
							overlayInnerStyle={{ fontSize: 16, width: "max-content" }}
							placement="right"
							title={
								measure?.verifiedBy
									? `Verified by ${measure?.verifiedBy} on ${dayjs(
											measure?.verifiedAt
									  ).format("DD-MM-YYYY hh:mm:ss a")}`
									: null
							}
						>
							<SafetyCertificateOutlined style={{ fontSize: 16 }} />
						</Tooltip>
					) : null}
				</Space>
			}
			onCancel={() => {
				setExpandedState({});
				customDashboardDispatch({ type: "CLOSE_DRILLDOWN" });
			}}
			open={customDashboardState.drilldownModal}
			onOk={() => customDashboardDispatch({ type: "CLOSE_DRILLDOWN" })}
			maskClosable={false}
			okText="Close"
		>
			<Table
				className="visual-drilldown-table"
				size="small"
				bordered
				loading={loading}
				dataSource={data}
				columns={columns}
				pagination={false}
				scroll={{ x: 2000, y: "max-content" }}
				tableLayout="fixed"
				virtual // Supports large datasource to be rendered virtually avoiding breaking Portal
			/>
		</Modal>
	);
};

export default DrilldownModal;
