import React from "react";
import {
	Button,
	Layout,
	Modal,
	Select,
	Input,
	Space,
	Tooltip,
	Flex,
	ConfigProvider,
	theme,
} from "antd";
import MainMenu from "../services/Menu";
import AdminMenu from "../services/AdminMenu";
import NotificationCenter from "./NotificationCenter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import StenaEvolve_logo from "../assets/img/StenaEvolve_horizontal_white.png";
import DarkBackground from "../assets/img/Portal_DarkBackground.png";
import DarkVerticialBackground from "../assets/img/Portal_DarkVerticalBackground.png";
import { Outlet } from "react-router-dom";
import file from "../../package.json";
import { getReleaseNotes } from "../services/api-server/documents";
import { connect } from "react-redux";
import { setHomeLogo } from "../utils/company_logo";
import Portal_logo from "../assets/img/Portal_logo_RGB_whitetext.png";
import PI_Evolve from "./../assets/img/product_icons/Stena_Evolve_E_White1.png";
import ScrollButton from "../components/BackToTopButton";
import Emitter from "../services/EventEmitter";
import { SendUserSubmissionMessage } from "../services/api-server/usermgt";
import BreadcrumbState2 from "../components/BreadcrumbState2";
import { ErrorBoundary } from "react-error-boundary";
import NoAccess from "./NoAccess";
import { optionsFilterSort, sendErrorNotification } from "../utils/utils";
import { ComponentList } from "../utils/components";
import { GetComponentIcon } from "../utils/svg_icons";
import { getComponents } from "../services/api-server/menu";
import { MenuOutlined } from "@ant-design/icons";
import MegaMenuV2 from "../services/MegaMenuv2";
import { Tenant } from "../services/api-server/_exports";
import { MenuContextProvider } from "../contexts/MenuContext";
import { MainContext } from "../contexts/MainContext";
import { GetAntIcon } from "../utils/ant_icons";
import PdfViewer from "../components/PdfViewer";

const { Content, Footer, Header, Sider } = Layout;
const { TextArea } = Input;

// context to store the handleOpenDocument function
export type HomeContextProps = {
	handleOpenDocument: ({ pdfViewer, fileObject, loading, open }: any) => void;
	handleUSModal: ({ pdfViewer, fileObject, loading }: any) => void;
};
export const HomeContext = React.createContext<HomeContextProps | undefined>(
	undefined
);

export class Home extends React.Component<any, any> {
	NotificationCenterVisible: boolean = false;
	counter: number = 0;
	menuObject: any;
	menuData: any;
	props: any = {
		isAdmin: false,
	};
	state: any = {
		componentList: [],
		errorBoundaryKey: 0,
		isCollapsed: false,
		secondary_color: "white",
		menudata: [],
		buttonVisible: false,
		userSubParams: {},
		mode: document.body.classList.contains("dark-mode"),
		// showFooter: true,
		docs: [],
		aditionalBreadcrumbs: [],
		showMenu: false,
	};

	user: any;

	constructor(props: any) {
		super(props);
		this.props = props;
		this.state.isCollapsed = false;
		this.state.secondary_color = "white";
		this.state.menudata = [];
		this.state.notificationCenterVisible = false;
		this.state.currentComponent = props.currentComponent;
		this.state.docs = [];
	}

	componentDidMount() {
		this.loadReleaseNotes();
		window.addEventListener("resize", this.resize);
		window.addEventListener("scroll", this.toggleVisible, true);

		this.resize();
		getComponents()
			.then((data: any) => {
				//console.log(data);
				this.setState({ componentList: data });
			})
			.catch((error: any) => {
				// console.log(error);
			});
	}

	componentDidUpdate(
		prevProps: Readonly<{}>,
		prevState: Readonly<{}>,
		snapshot?: any
	): void {
		if (this.props.currentComponent !== this.state.currentComponent) {
			this.setState({
				currentComponent: this.props.currentComponent,
				componentSelected: null,
				selectedKey: this.props.defaultKey,
			});
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize);
	}

	toggleVisible = () => {
		if (this.props.ismobile) {
			const scrolled: any = document.getElementById("content")?.scrollTop;
			if (scrolled > 300) {
				this.setState({ buttonVisible: true });
			} else {
				this.setState({ buttonVisible: false });
			}
		}
	};

	resize = () => {
		let hideNav = window.innerWidth <= 1200;
		this.setState({ hideNav: hideNav });
		this.onCollapse(hideNav);
		this.props.dispatch({
			type: "SET_COLLAPSE",
			payload: hideNav,
		});
	};

	onCollapse(collapsed: any) {
		this.setState({ isCollapsed: collapsed });
	}
	openNotification() {
		this.setState({ notificationCenterVisible: true, NCounter: 0 });
		localStorage.setItem(`${Tenant}:NCounter`, "0");
	}

	handleNotificationCenterOk(ev: any) {
		if (ev.target?.value) {
			// console.log(ev.target.value);
		}
		this.setState({ notificationCenterVisible: false });
	}

	updateUSParams = (key: any, value: any) => {
		let Params = { ...this.state.userSubParams };
		Params[key] = value;
		this.setState({ userSubParams: Params });
	};

	loadReleaseNotes = () => {
		const currentDate = Date.now();
		//One Month duration of release notes
		const startdate = currentDate - 3.156 * Math.pow(10, 10);

		getReleaseNotes().then((res: any) => {
			// console.log(res.data.data)
			let releasenotes = res.data.data["releasenotes"];
			let filtered_releasenotes: any = [];
			Promise.all(
				Object.keys(releasenotes)
					.sort(
						(a: any, b: any) =>
							Date.parse(releasenotes[b].date) -
							Date.parse(releasenotes[a].date)
					)
					.splice(0, 2)
					.map((element: any) => {
						const releasedate = Date.parse(releasenotes[element].date);
						let md5 = undefined;
						if (releasedate <= currentDate && releasedate >= startdate) {
							filtered_releasenotes.push(releasenotes[element]);
							md5 = releasenotes[element].md5;
						}
						return md5;
					})
			).then((data: any) => {
				// console.log(filtered_releasenotes)
				this.props.dispatch({
					type: "SET_RELEASENOTES",
					payload: filtered_releasenotes,
				});
				data = data.filter((e: any) => e !== undefined);
				if (!localStorage.getItem(`${Tenant}:releaseNotes`)) {
					localStorage.setItem(`${Tenant}:releaseNotes`, data);
					localStorage.setItem(`${Tenant}:NCounter`, "0");
				} else {
					const previousdata: any = localStorage
						.getItem(`${Tenant}:releaseNotes`)
						?.split(",");
					let counter: any = parseInt(
						localStorage.getItem(`${Tenant}:NCounter`) || "0"
					);
					const data_diff = data.filter((x: any) => !previousdata.includes(x));

					counter = counter + data_diff.length;
					localStorage.setItem(`${Tenant}:releaseNotes`, data);
					localStorage.setItem(`${Tenant}:NCounter`, `${counter}`);
					this.setState({ NCounter: counter });
				}
			});
		});
	};

	SendUserSubmission = () => {
		let Params = { ...this.state.userSubParams };
		let Component = this.state.componentSelected;

		if (
			Params.title?.trim().length > 0 &&
			Component &&
			Params.description.trim().length > 0 &&
			Params.justification.trim().length > 0
		) {
			Params.title = Params.title?.trim();
			Params.description = Params.description?.trim();
			Params.justification = Params.justification?.trim();
			Params.component = Component;
			Params.email = this.props.user.preferred_username;
			SendUserSubmissionMessage(Params)
				.then((res: any) => {
					Emitter.emit("alert", {
						type: "success",
						message: "Your suggestion has been sent!",
						description: "We will review it shortly.",
						timeout: 5000,
					});
				})
				.catch((err: any) => {
					Emitter.emit("alert", {
						type: "error",
						message: "Failed to send request",
						description:
							"There is something wrong with the request, please contact the Administrator for help",
						timeout: 5000,
					});
				});
			this.setState({
				Visible_UserSubmissionModal: false,
			});
		} else {
			Emitter.emit("alert", {
				type: "error",
				message: "Failed to send request",
				description:
					"All fields provided must be filled in before the request can be sent",
				timeout: 5000,
			});
		}
	};

	handleOpenDocument = ({ pdfViewer, fileObject, loading, open }: any) => {
		console.log(pdfViewer, fileObject, loading, open);
		// Handling displaying document on mobile screens
		// if (this.props.ismobile) {
		// 	// const windowReference = window.open()
		// 	// if (windowReference)
		// 	// 	windowReference.location = fileObject.children

		// 	// window.open(fileObject.children, "_blank");
		// 	setTimeout(() => {
		// 		window.open(fileObject.children, '_blank');
		// 	})
		// }
		// Handling displaying document on desktop screens
		// else {
		this.setState({
			pdfViewer,
			fileObject,
			loading,
			documentOpen: open,
		});
		// }
	};

	handleUSModal = ({ ...props }) => {
		let params = { ...this.state.userSubParams };
		params.title = props.message;
		this.setState({
			Visible_UserSubmissionModal: true,
			componentSelected: props.component,
			userSubParams: params,
		});
	};

	setAdditionalBreadcrumbs = (newBreadcrumbs: any, newTree?: any) => {
		// console.log({ bread: newBreadcrumbs });
		this.setState({ aditionalBreadcrumbs: newBreadcrumbs });

		if (newTree) {
			const changePage: any = this.context;
			changePage(newBreadcrumbs, newTree);
			// console.log(newTree);
		}
	};

	onSelect = (selectedKey: string) => {
		this.setState({
			selectedKey,
			errorBoundaryKey: this.state.errorBoundaryKey + 1,
		});
	};

	handleErrorFallback = (props: any) => {
		return (
			<NoAccess
				text={"An unexpected has occured on this page, please try again later"}
				subtext={
					<span>
						Contact support:{" "}
						<a href="mailto: portal-support@stena-evolve.com">
							portal-support@stena-evolve.com
						</a>
					</span>
				}
				setErrorBoundaryKey={() => {
					this.setState({ errorBoundaryKey: this.state.errorBoundaryKey + 1 });
				}}
			/>
		);
	};
	logError = (error: any, info: any) => {
		if (process.env.NODE_ENV === "production") {
			error.location = window.location.pathname;
			error.level = "component";
			let JSONerror = JSON.stringify(error, Object.getOwnPropertyNames(error));
			sendErrorNotification(JSONerror);
		}
	};
	render() {
		const preload_images = () => {
			return (
				<>
					<img src={DarkBackground} hidden alt="full"></img>
					<img src={DarkVerticialBackground} alt="vertical" hidden />
				</>
			);
		};
		const renderFSModal = () => {
			let componentSelected =
				this.state.componentSelected || this.props.currentComponent;

			let componentList = [
				{ key: "Something New" },
				...this.state.componentList,
			];
			return (
				<ConfigProvider
					theme={{ algorithm: theme.darkAlgorithm, inherit: false }}
				>
					<Modal
						className="UserSubmission-modal"
						title={<span style={{ fontWeight: "bold" }}>Evolve</span>}
						open={this.state.Visible_UserSubmissionModal}
						destroyOnClose={true}
						centered
						onCancel={() => {
							this.setState({
								Visible_UserSubmissionModal: false,
								userSubParams: {},
								componentSelected: this.props.currentComponent,
							});
						}}
						width={"1000px"}
						styles={{
							body: {
								padding: "34px 24px",
								display: "flex",
								justifyContent: "center",
							},
						}}
						zIndex={20000}
						footer={[
							<Button
								onClick={() =>
									this.setState({
										Visible_UserSubmissionModal: false,
										userSubParams: {},
										componentSelected: this.props.currentComponent,
									})
								}
							>
								Cancel
							</Button>,
							<Button
								style={{ width: "initial" }}
								className="button-general"
								type="primary"
								onClick={() => this.SendUserSubmission()}
							>
								Submit
							</Button>,
						]}
					>
						<div style={{ maxWidth: "80%", minWidth: "80%" }}>
							<div
								style={{
									textAlign: "center",
									display: "flex",
									justifyContent: "center",
									flexDirection: "column",
									marginBottom: "20px",
								}}
							>
								<div
									style={{
										fontWeight: "bold",
										fontSize: "1.5em",
										marginBottom: "10px",
									}}
								>
									Your ideas are important to us!
								</div>
								<div
									style={{
										fontSize: "1.1em",
										width: "80%",
										alignSelf: "center",
									}}
								>
									Got an idea for a new feature that would improve Portal?
									Please let us know!
								</div>
							</div>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									marginBottom: "20px",
								}}
							>
								<span>Component</span>
								<Select
									key={"Component Selection"}
									value={componentSelected}
									onChange={(ev) => this.setState({ componentSelected: ev })}
									options={componentList
										.filter(
											(element) => element.key !== "None" && !element.disabled
										)
										.map((element) => ({
											label: element.key,
											value: element.key,
										}))}
									filterSort={(a, b) => {
										return a.label === "Something New" ||
											b.label === "Something New"
											? 1
											: optionsFilterSort(a, b);
									}}
									optionRender={(option, info) => {
										// console.log({ option, info });
										const element = ComponentList.find(
											(component) => component === option.data.label
										);

										if (!element) return option.label;
										if (element === "Digital Twin")
											return (
												<Flex gap={8}>
													{GetComponentIcon({
														componentName: element,
														width: 14,
														height: 22,
													})}
													{option.label}
												</Flex>
											);

										return (
											<Space>
												{GetComponentIcon({ componentName: element })}
												{option.label}
											</Space>
										);
									}}
								/>
							</div>
							<div style={{ marginBottom: "20px" }}>
								<span>Title</span>
								<Input
									key={"US_Title"}
									value={this.state.userSubParams?.title}
									onChange={(ev) =>
										this.updateUSParams("title", ev.target.value)
									}
								></Input>
							</div>
							<div style={{ marginBottom: "20px" }}>
								<span>Feature Description</span>
								<TextArea
									key={"Feature Description"}
									rows={5}
									onChange={(ev) =>
										this.updateUSParams("description", ev.target.value)
									}
								></TextArea>
								<span className="subtext">
									Describe the new feature and how it would work
								</span>
							</div>
							<div>
								<span>Purpose of the feature</span>
								<TextArea
									key={"Justification of feature"}
									rows={5}
									onChange={(ev) =>
										this.updateUSParams("justification", ev.target.value)
									}
								></TextArea>
								<span className="subtext">
									Describe the purpose of the feature and what it will help you
									achieve
								</span>
							</div>
						</div>
					</Modal>
				</ConfigProvider>
			);
		};

		let locations: string[] = window.location.pathname.split("/");
		locations.shift();

		const toggleMenu = (boolean: any = null) => {
			let showMenuBool = !this.state.showMenu;
			if (boolean === false) {
				showMenuBool = false;
			}
			// console.log(boolean);
			this.setState({
				showMenu: showMenuBool,
				currentSubKey: "",
			});
		};

		const setSubmenuKey = (selectedKey: string) => {
			this.setState({ currentSubKey: selectedKey });
		};

		return (
			/**
			 * the handleOpenDocument is provided in HomeContext to be used in places that
			 * requires handling viewing pdf files
			 */
			<MainContext.Consumer>
				{({ fullScreen }) => {
					return (
						<HomeContext.Provider
							value={{
								handleOpenDocument: this.handleOpenDocument,
								handleUSModal: this.handleUSModal,
							}}
						>
							{
								this.props.user
									? (
										!this.props.ismobile ? (
											<Layout key="asz" className="site-whole">
												<Layout key="asz2" className="site-layout">
													<Header
														key="asz3"
														className="site-layout-header"
														style={{
															padding: 16,
															display: !fullScreen ? "flex" : "none",
															alignItems: "center",
															justifyContent: "space-between",
														}}
													>
														<Flex gap={8}>
															<MenuOutlined
																style={{ fontSize: 24 }}
																onClick={() =>
																	this.setState({ showMenu: !this.state.showMenu })
																}
															/>
															<img
																style={{ flex: "0 0 auto", marginRight: 48 }}
																className={
																	this.props.collapsed
																		? "portal-small-logo"
																		: "portal-logo"
																}
																src={
																	this.props.collapsed ? Portal_logo : Portal_logo
																}
																alt="portal-logo"
															/>
														</Flex>

														<Flex>
															{setHomeLogo()}
															<Space className="site-btns">
																<Tooltip title="Submit your request here!">
																	<div
																		className="hexagon-button site-button"
																		// className="site-button"
																		style={{
																			cursor: "pointer",
																		}}
																		onClick={() =>
																			this.setState({
																				Visible_UserSubmissionModal: true,
																			})
																		}
																	>
																		<img
																			alt={"Evolve Logo"}
																			className="site-button-icon"
																			src={PI_Evolve}
																		/>
																	</div>
																</Tooltip>
																<div
																	className="hexagon-button site-button site-button-icon"
																	style={{ cursor: "pointer" }}
																	onClick={this.openNotification.bind(this)}
																>
																	{this.state.NCounter > 0 ? (
																		<div className="notification-counter">
																			{this.state.NCounter}
																		</div>
																	) : null}
																	<FontAwesomeIcon
																		className="site-button-icon"
																		icon={faBell}
																	/>
																</div>
																<AdminMenu toggle={() => toggleMenu(false)} />
															</Space>
														</Flex>
													</Header>
													<Layout>
														<Sider
															// width={"100%"}
															style={{ background: "rgb(36, 37, 37)" }}
															collapsible
															collapsed={!this.state.showMenu}
															collapsedWidth={0}
															trigger={null}
															width={this.state?.currentSubKey ? "100%" : 300}
														>
															{/* <Drawer
														placement="left"
														getContainer={false}
														rootStyle={{ position: "absolute" }}
													> */}
															<MenuContextProvider
																toggle={toggleMenu}
																setSubmenuKey={setSubmenuKey}
																currentSubKey={this.state.currentSubKey}
																open={this.state.showMenu}
															>
																<Flex>
																	<MainMenu toggleMenu={toggleMenu} />
																	<MegaMenuV2 />
																</Flex>
															</MenuContextProvider>

															{/* </Drawer> */}
														</Sider>
														{/* <div className={"content-window"}> */}
														<Content key="asz6">
															{!fullScreen ? (
																<BreadcrumbState2
																	show={true}
																	onClick={(breadcrumb) => {
																		this.onSelect(breadcrumb?.key);
																	}}
																	defaultKey={this.props.homeKey}
																/>
															) : null}

															<ErrorBoundary
																resetKeys={[this.state.errorBoundaryKey]}
																fallbackRender={this.handleErrorFallback}
																onError={this.logError}
															>
																<Outlet />
															</ErrorBoundary>
														</Content>
														{/* </div> */}
													</Layout>

													<Footer key="asz10" className="site-layout-footer">
														<span
															style={{
																fontSize: "14px",
																paddingTop: "5px",
																color: "grey",
																marginLeft: "2%",
																alignSelf: "center",
															}}
														>
															Version {file.version}{" "}
														</span>
														<span
															style={{
																fontSize: "14px",
																paddingTop: "5px",
																color: "grey",
																marginLeft: "auto",
																marginRight: "20px",
																alignSelf: "center",
																whiteSpace: "pre",
															}}
														>
															{" "}
															Copyright <span style={{ fontSize: "18px" }}>
																©
															</span>{" "}
															{new Date().getFullYear()}
														</span>
														<img
															className="home-footer-logo"
															src={StenaEvolve_logo}
															alt="Stena"
														/>
													</Footer>
												</Layout>
											</Layout>
										) : (
											<Layout key="asz" className="site-whole">
												<Layout key="asz2" className="site-layout">
													<Header
														key="asz3"
														className="site-layout-header"
														style={{
															padding: "0 12px",
															display: "flex",
															justifyContent: "space-between",
														}}
													>
														<Flex justify="space-between" gap={8}>
															<MenuOutlined
																style={{ fontSize: 18 }}
																onClick={() =>
																	this.setState({ showMenu: !this.state.showMenu })
																}
															/>
															<img
																className="portal-logo-mobile"
																src={Portal_logo}
																alt="portal-logo"
															/>
														</Flex>
														<div
															style={{
																display: "flex",
																alignItems: "center",
																height: "100%",
															}}
														>
															<div
																className="hexagon-button site-button"
																style={{
																	cursor: "pointer",
																	display: "flex",
																	marginLeft: "auto",
																}}
																onClick={() =>
																	this.setState({
																		Visible_UserSubmissionModal: true,
																	})
																}
															>
																<img
																	alt={"Evolve Logo"}
																	style={{
																		width: "14px",
																	}}
																	src={PI_Evolve}
																></img>
															</div>
															{/* <Button
													style={{
														backgroundColor: "#E32118",
														marginLeft: "auto",
														marginRight: "15px",
														display: "flex",
														alignSelf: "center",
														alignItems: "center",
														justifyContent: "center",
														color: "white",
														border: "none",
														width: "83px",
														height: "49px",
													}}
													className={"stopFocus-White"}
													icon={
														<img
															alt={"Evolve Logo"}
															style={{
																width: "20px",
																height: "24px",
																marginRight: "5px",
															}}
															src={PI_Evolve}
														></img>
													}
													onClick={() =>
														this.setState({ Visible_UserSubmissionModal: true })
													}
													// className="site-button"
												>
													<span style={{ verticalAlign: "middle" }}>Evolve</span>
												</Button> */}
												</div>
											</Header>
											<Layout id="mobile-layout">
												<Sider
													collapsedWidth={0}
													collapsible
													collapsed={!this.state.showMenu}
													style={{
														background: "rgb(36, 37, 37)",
														// transition: "all ",
													}}
													width={"100%"}
													trigger={null}
												>
													<MenuContextProvider toggle={toggleMenu}>
														<MainMenu toggleMenu={toggleMenu} />
													</MenuContextProvider>
												</Sider>
												{/* <Drawer
													onClose={() => this.setState({ showMenu: false })}
													placement="left"
													open={this.state.showMenu}
													// open
													getContainer={false}

													// closeIcon={null}
												> */}
														{/* </Drawer> */}
														<Content key="asz6" id="asz6" className="main-content">
															<Outlet />
														</Content>

														{/* <div className={"content-window"} id="content"> */}

														<ScrollButton visible={this.state.buttonVisible} />
														{/* </div> */}
													</Layout>

													<Footer
														key="asz10"
														className="site-layout-footer"
														style={{
															justifyContent: "space-between",
															padding: "0 5px",
														}}
													>
														<span
															style={{
																fontSize: "11px",
																color: "grey",
																alignSelf: "center",
																whiteSpace: "pre",
															}}
														>
															Powered by Stena Evolve{"   "}© Copyright{" "}
															{new Date().getFullYear()}
														</span>
														<img
															className="home-footer-logo"
															style={{ marginRight: "0px", width: "80px" }}
															src={StenaEvolve_logo}
															alt="Stena"
														/>
													</Footer>
												</Layout>
											</Layout>
										)
									) : null}
							<Modal
								className="notification-modal"
								wrapClassName="notification-wrapper"
								styles={{ body: { padding: "0" } }}
								width={"350px"}
								open={this.state.notificationCenterVisible}
								onCancel={this.handleNotificationCenterOk.bind(this)}
								footer={null}
								mask={false}
								closable={false}
							>
								<NotificationCenter></NotificationCenter>
							</Modal>
							{preload_images()}
							{renderFSModal()}
							<PdfViewer
								fileObject={this.state.fileObject}
								pdfViewer={this.state.pdfViewer}
								handleCloseDocument={this.handleOpenDocument}
								open={this.state.documentOpen}
							/>
						</HomeContext.Provider>
					);
				}}
			</MainContext.Consumer>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		ismobile: state.ismobile,
		currentModule: state.currentModule,
		currentComponent: state.currentComponent,
		allComponents: state.allComponents,
		user: state.user,
		vessels: state.vessels.allVessels,
	};
};

export default connect(mapStateToProps)(Home);
